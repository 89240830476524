.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  .button {
    min-width: 140px;
  }
}
/* roboto-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/roboto-v30-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/roboto-v30-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-slab-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/roboto-slab-v25-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #C8EA8E;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 500;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.44444444;
}
.unit caption {
  display: none;
}
.flag {
  background: #C8EA8E;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
  position: relative;
}
@media (max-width: 1023px) {
  .unit {
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 14px;
  }
}
@media (max-width: 1023px) {
  .unit {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-bottom: 14px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
@media (max-width: 1023px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .part {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-bottom: 10px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #868686;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 12px;
  position: relative;
}
.list--bullet li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 5px solid #868686;
  border-bottom: 5px solid transparent;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  height: 704px !important;
}
@media (max-width: 1023px) {
  #slides {
    height: 500px !important;
  }
}
@media (max-width: 767px) {
  #slides {
    height: 420px !important;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #C8EA8E;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #C8EA8E;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.42857143;
  color: #868686;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #C8EA8E;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #C8EA8E;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    min-width: 140px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
  font-size: 14px;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  min-height: 50px;
  padding: 5px 10px;
  -webkit-appearance: none;
  border: 1px solid #868686;
  border-radius: 4px;
  transition: all 0.2s;
}
.unit.form input.text:focus,
.unit.form textarea:focus {
  box-shadow: 0 0 0 3px rgba(200, 234, 142, 0.5);
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form .word.tile fieldset {
  display: flex;
  align-items: flex-start;
}
.unit.form .word.tile input.text {
  order: 1;
  width: 50px;
  text-align: center;
  margin-right: 20px;
}
.unit.form .word.tile label.name {
  order: 2;
  width: calc(100% - 70px);
  min-height: 50px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 12px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 1.44444444;
  background: transparent;
  height: 50px;
  border: 1px solid #868686;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    min-width: 140px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #868686;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    min-width: 140px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 500;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 500;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.34680574vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
.homelink {
  float: left;
  margin-left: 20px;
  position: relative;
}
.logo {
  width: auto;
  height: 100%;
}
.logo.logo--picto {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 1.44444444;
  color: #868686;
  overflow: hidden;
  hyphens: none;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  padding-top: 200px;
}
@media (max-width: 1023px) {
  .wrapper {
    padding-top: 130px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding-top: 96px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: calc(100% - 40px);
  width: 1158px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  background-color: #fff;
}
.cb-layout2 .section--multimood {
  background-color: #f1fae3;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1023px) {
  .cb-layout2 .section--multimood {
    height: 220px;
  }
}
.cb-layout3 .section--multimood {
  display: none;
}
@media (max-width: 1023px) {
  .section--multimood {
    margin-bottom: 133px;
  }
}
@media (max-width: 767px) {
  .section--multimood {
    margin-bottom: 0;
  }
}
.section--green {
  background-color: #f1fae3;
}
.section--footer {
  background-color: #2C2C2C;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}
.section--footer a {
  color: #fff;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #FFD08E;
}
.section--credits {
  background-color: #FF7E23;
  font-size: 14px;
  line-height: 1.85714286;
  color: #fff;
}
.section--credits a {
  color: #fff;
}
.section--credits a:hover,
.section--credits a:focus {
  color: #FFD08E;
}
.headercontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #868686;
}
@media (max-width: 1023px) {
  .headercontent {
    justify-content: flex-start;
  }
}
.servicenavi {
  float: left;
  display: flex;
  align-items: center;
  margin-right: 100px;
}
@media (max-width: 1023px) {
  .servicenavi {
    margin-left: auto;
    margin-right: 30px;
  }
}
.servicenavi .meta.find {
  float: left;
  margin-left: 40px;
  width: 26px;
  height: 26px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-gray.svg);
}
.servicenavi .meta.find:hover {
  background-image: url(/images/icon-search-black.svg);
}
@media (max-width: 1023px) {
  .servicenavi .meta.find {
    margin-left: 0;
  }
}
.servicenavi .service_faq {
  float: left;
  margin-left: 40px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 1.25;
  background-size: 14px 14px;
  background-position: 100% 2px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-extern-gray.svg);
  color: #868686;
}
.servicenavi .service_faq:hover {
  color: #000;
  background-image: url(/images/icon-extern-black.svg);
}
@media (max-width: 1023px) {
  .servicenavi .service_faq {
    display: none;
  }
}
.mainnavi {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 100px;
  border-bottom: 1px solid #868686;
}
@media (max-width: 1023px) {
  .mainnavi {
    display: none;
  }
}
.moodcontent {
  float: left;
  width: 100%;
  height: 0;
}
.moodcontent .desk {
  height: 0;
}
.moodcontent.moodcontent--circle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
@media (max-width: 767px) {
  .moodcontent {
    height: auto;
  }
  .moodcontent .desk {
    height: auto;
  }
  .moodcontent.moodcontent--circle {
    position: relative;
  }
}
.moodcircle {
  position: absolute;
  right: 80px;
  top: -30px;
  z-index: 2;
}
@media (max-width: 767px) {
  .moodcircle {
    position: static;
    float: left;
    width: 100%;
    margin-top: 10px;
  }
}
.moodsquare {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 620px;
  min-height: 360px;
  box-sizing: border-box;
  padding: 27px 40px 58px;
  z-index: 2;
  background-color: #C8EA8E;
}
@media (max-width: 1023px) {
  .moodsquare {
    width: 604px;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    min-height: 266px;
    padding: 20px 30px 48px 30px;
  }
}
@media (max-width: 767px) {
  .moodsquare {
    position: static;
    width: 100%;
    margin-top: -106px;
    transform: none;
    min-height: 212px;
    padding: 10px 20px 48px 20px;
  }
}
.content {
  float: left;
  width: 100%;
}
#view.side--empty .content--base {
  border-top: 1px solid #D0D0D0;
}
#view.base--empty .content--base {
  display: none;
}
.footcontent {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 100px;
  margin: 30px 0 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.footpart {
  float: left;
  margin-left: 146px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footpart:first-child {
  margin-left: 0;
}
.footpart--verband {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.footlogo {
  float: left;
  width: auto;
  margin-left: -60px;
  height: 86px;
}
.footlogo--verband {
  width: 100%;
  height: 60px;
  margin-right: -22px;
}
.footlogo--verband img {
  width: auto;
  height: 60px;
}
.vcard {
  float: left;
  width: 300px;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-font-smoothing: antialiased;
}
.vcard .vcardtitle {
  font-weight: 500;
}
@media (max-width: 1023px) {
  .vcard {
    font-size: 14px;
    line-height: 1.57142857;
  }
}
.credits {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 9px 0;
}
@media (max-width: 1023px) {
  .credits {
    font-size: 11px;
    line-height: 2.36363636;
  }
}
.legal {
  float: left;
}
.copyright {
  float: left;
}
#services {
  float: left;
}
#services .meta {
  float: left;
}
#services .meta:before {
  content: '|';
  float: left;
  margin: 0 8px;
  color: #fff !important;
}
#brands .meta {
  padding: 0 8px;
}
#brands .meta:first-child {
  padding-left: 0;
}
#brands .meta:last-child {
  padding-right: 0;
}
.downlink {
  position: absolute;
  right: 40px;
  bottom: 30px;
  z-index: 2;
  cursor: pointer;
  width: 33px;
  height: 18px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/corner-down-black.svg);
  display: none;
}
.cb-layout1 .downlink {
  display: block;
}
@media (max-width: 1023px) {
  .downlink {
    bottom: 20px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  .downlink {
    right: 20px;
  }
}
.quicklinks {
  position: fixed;
  right: 0;
  top: 350px;
  z-index: 1900;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
}
.cb-layout2 .quicklinks {
  top: 280px;
}
.cb-layout3 .quicklinks {
  display: none;
}
.quicklinks.cb-scroll-triggered--active {
  transform: translateX(100%);
}
@media (max-width: 1023px) {
  .quicklinks {
    top: 210px;
  }
  .cb-layout2 .quicklinks {
    top: 160px;
  }
}
@media (max-width: 767px) {
  .quicklinks {
    top: 140px !important;
  }
}
.quicklink {
  float: left;
  height: 80px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px 20px 5px 80px;
  display: flex;
  align-items: center;
  background-color: #FF7E23;
  color: #fff !important;
  font-size: 18px;
  line-height: 1;
  border-radius: 4px 0 0 4px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.18);
  transition: all 0.4s;
}
.quicklink:first-child {
  margin-top: 0;
}
.quicklink.quicklink--smsDienst {
  background-position: 26px center;
  background-image: url(/images/icon-sms-blackWhite.svg);
}
.quicklink.quicklink--calendar {
  background-position: 20px center;
  background-image: url(/images/icon-calendar.svg);
}
.quicklinks.cb-scroll-triggered--active .quicklink {
  margin-left: -80px;
}
@media (min-width: 1024px) {
  .quicklinks.cb-scroll-triggered--active .quicklink:hover {
    margin-left: 0;
    transform: translateX(-100%);
  }
}
@media (max-width: 1023px) {
  .quicklink {
    height: 60px;
    padding-left: 60px;
    background-size: 34px 34px;
    font-size: 16px;
  }
  .quicklink.quicklink--smsDienst {
    background-position: 16px center;
  }
  .quicklink.quicklink--calendar {
    background-position: 13px center;
    background-image: url(/images/icon-calendar.svg);
  }
  .quicklinks.cb-scroll-triggered--active .quicklink {
    margin-left: -60px;
  }
}
a {
  color: #868686;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #7BA82C;
}
h1 {
  font-size: 20px;
  line-height: 2;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: normal;
  color: #000;
}
h2 {
  font-size: 50px;
  line-height: 1.1;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
  hyphens: manual;
}
h3 {
  font-size: 35px;
  line-height: 1.14285714;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
}
h4 {
  font-size: 30px;
  line-height: 1.26666667;
  font-weight: 500;
  color: #000;
  letter-spacing: 0;
}
h5 {
  font-size: 24px;
  line-height: 1.16666667;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
}
h6 {
  font-weight: 500;
  color: #000;
  border-bottom: 1px solid #868686;
  padding-bottom: 12px;
}
.loud {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0;
}
.loud a {
  text-decoration: underline;
}
.skew {
  color: #000;
  display: flex;
  align-items: flex-start;
  font-size: 22px;
  line-height: 1.27272727;
  font-weight: 500;
  padding-top: 9px;
}
.skew strong {
  flex-shrink: 0;
  display: inline-block;
  margin-top: -9px;
  margin-right: 16px;
  width: 46px;
  height: 46px;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
}
#view .skew i {
  flex-shrink: 0;
  display: inline-block;
  margin-top: -9px;
  margin-right: 16px;
  width: 46px;
  height: 46px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-star.svg);
}
p.norm strong {
  color: #000;
}
p.norm a {
  text-decoration: underline;
}
.section--one .area {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-top: 68px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-top: 48px;
  }
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-bottom: 68px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-bottom: 48px;
  }
}
.section--two .area {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-bottom: 100px;
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-top: 68px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-top: 48px;
  }
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-bottom: 68px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: 48px;
  }
}
.moodcircle .area {
  width: 274px;
  height: 274px;
  box-sizing: border-box;
  padding: 55px 25px 25px;
  background-color: #FFD08E;
  border-radius: 100000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
}
.moodcircle .area a {
  color: #000;
  text-decoration: underline;
  font-weight: 500;
}
.moodcircle .area a:hover,
.moodcircle .area a:focus {
  color: #fff;
}
@media (max-width: 1023px) {
  .moodcircle .area {
    width: 222px;
    height: 222px;
    padding: 20px 20px 0;
  }
}
@media (max-width: 767px) {
  .moodcircle .area {
    width: 100%;
    border-radius: 0;
    padding: 12px 20px;
    text-align: left;
    height: auto;
  }
}
.section--two .area .pure.wide div.line hr {
  border-color: #D0D0D0;
}
.section--two .area .seam.slim {
  text-align: center;
}
.section--two .area .seam.slim .pict .cb-image-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.section--two .area .edge {
  margin-bottom: -35px;
}
@media (max-width: 1023px) {
  .section--two .area .edge {
    margin-bottom: -24px;
  }
}
@media (max-width: 767px) {
  .section--two .area .edge {
    margin-bottom: -14px;
  }
}
.section--two .area .fold {
  border: 1px solid #868686;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .section--two .area .fold {
    margin-top: 34px;
  }
}
@media (max-width: 767px) {
  .section--two .area .fold {
    margin-top: 24px;
  }
}
@media (max-width: 1023px) {
  .section--two .area .fold {
    margin-bottom: 34px;
  }
}
@media (max-width: 767px) {
  .section--two .area .fold {
    margin-bottom: 24px;
  }
}
.section--two .area .fold .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.section--two .area .fold .part {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.section--two .area .fold .part.line {
  width: calc(100% + 20px) !important;
  margin-left: -10px !important;
  flex-shrink: 0;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
@media (max-width: 1023px) {
  .section--two .area .fold .part.line {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .section--two .area .fold .part.line {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .section--two .area .fold .part.line {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .section--two .area .fold .part.line {
    margin-bottom: 10px;
  }
}
.section--two .area .fold .part.line hr {
  border-color: #868686;
}
.section--two .area .fold .less {
  position: relative;
}
.section--two .area .fold .less .part {
  margin-top: 0;
  margin-bottom: 0;
}
#edit .section--two .area .fold .less .pict:first-child {
  margin: 10px !important;
  width: 40px !important;
}
#view .section--two .area .fold .less .pict:first-child {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  pointer-events: none;
  width: 40px !important;
}
@media (max-width: 767px) {
  #view .section--two .area .fold .less .pict:first-child {
    width: 30px !important;
    left: 8px;
  }
}
.section--two .area .fold .less .part.pict + .part.text .fold-toggle {
  padding-left: 64px;
}
@media (max-width: 767px) {
  .section--two .area .fold .less .part.pict + .part.text .fold-toggle {
    padding-left: 46px;
  }
}
.section--two .area .fold .more {
  border-top: 1px solid #868686;
  box-sizing: border-box;
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
}
.section--two .area .fold .more .part.text.tall .text-section {
  max-width: 654px;
}
.section--two .area .fold .more .part.line + .part.text,
.section--two .area .fold .more .part.line + .part.text + .part.text,
.section--two .area .fold .more .part.line + .part.text + .part.text + .part.text {
  padding-top: 48px;
  background-size: 40px 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/fold-text-icon-gray.svg);
}
.section--two .area .fold .more .part.line + .part.text + .part.text {
  background-image: url(/images/fold-text-icon-green.svg);
}
.section--two .area .fold .more .part.line + .part.text + .part.text + .part.text {
  background-image: url(/images/fold-text-icon-red.svg);
}
.section--two .area .fold .fold-toggle {
  display: block;
  box-sizing: border-box;
  padding-left: 64px;
  text-decoration: none;
  color: #000;
  min-height: 60px;
  padding: 5px 58px 5px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-size: 22px 12px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-down-gray.svg);
}
.section--two .area .fold .fold-toggle:hover,
.section--two .area .fold .fold-toggle:focus {
  background-color: #C8EA8E;
  background-image: url(/images/corner-down-black.svg);
}
.section--two .area .fold .fold-toggle.fold-toggle--open {
  background-color: #C8EA8E;
  background-image: url(/images/corner-up-black.svg);
}
@media (max-width: 767px) {
  .section--two .area .fold .fold-toggle {
    min-height: 40px;
    padding-left: 10px;
  }
}
.section--two .area .fold .foot {
  display: none;
}
.moodcircle .area .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.moodcircle .area .unit .part {
  margin-top: 8px;
  margin-bottom: 8px;
}
.moodcircle .area .unit h5 {
  font-size: 30px;
  line-height: 1.33333333;
}
@media (max-width: 1023px) {
  .moodcircle .area .unit h5 {
    font-size: 16px;
    line-height: 1.25;
  }
}
.moodsquare .area .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.moodsquare .area .unit .part {
  margin-top: 8px;
  margin-bottom: 8px;
}
div.link a.open {
  display: inline-block;
  background-color: transparent;
  line-height: 1.44444444;
  cursor: pointer;
  color: #868686;
  padding: 11px 46px 11px 18px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align-last: left;
  border: 1px solid #868686;
  min-width: 200px;
  transition: all 0.3s;
  background-size: 10px 18px;
  background-position: right 18px center;
  background-repeat: no-repeat;
  background-image: url(/images/corner-right-gray.svg);
}
div.link a.open:hover,
div.link a.open:focus {
  color: #000;
  background-color: #C8EA8E;
  border-color: #C8EA8E;
  background-image: url(/images/corner-right-black.svg);
}
@media (max-width: 1023px) {
  div.link a.open {
    min-width: 140px;
  }
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  float: right;
  margin-right: 40px;
  position: relative;
  width: 36px;
  height: 22px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .togglenavigation {
    margin-right: 20px;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 2px;
  background-color: #868686;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobnavipart {
  float: left;
  width: 100%;
  max-width: 362px;
  border-bottom: 1px solid #868686;
  padding-bottom: 38px;
}
.mobnavipart.mobnavipart--mainNavi {
  margin-top: 220px;
}
@media (max-width: 767px) {
  .mobnavipart.mobnavipart--mainNavi {
    margin-top: 150px;
  }
}
.mobnavipart.mobnavipart--serviceNavi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 90px;
}
@media (max-width: 767px) {
  .mobnavipart.mobnavipart--serviceNavi {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .mobnavipart {
    width: 262px;
  }
}
.mobnavipart .service_faq {
  float: left;
  margin-top: 36px;
  font-size: 18px;
  line-height: 1.66666667;
  padding-right: 20px;
  background-size: 14px 14px;
  background-position: 100% 7px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-extern-gray.svg);
  color: #868686 !important;
}
.mobile-navigation div.navi {
  float: right;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
}
.mobnavipart--mainNavi div.sub1 > .item {
  margin: 18px 0;
}
.mobnavipart--mainNavi div.sub1 > .item.init {
  margin-top: 0;
}
.mobnavipart--mainNavi div.sub1 > .item.exit {
  margin-bottom: 0;
}
.mobnavipart--mainNavi div.sub1 > .item > .menu {
  float: left;
  font-size: 22px;
  line-height: 1.36363636;
  color: #000;
  letter-spacing: 0.01em;
  position: relative;
}
.mobnavipart--mainNavi div.sub1 > .item > .menu:before {
  content: '';
  position: absolute;
  left: -5px;
  bottom: 0;
  width: calc(100% + 10px);
  height: 10px;
  background-color: #C8EA8E;
  z-index: -1;
  transition: all 0.3s;
  display: none;
}
.mobnavipart--mainNavi div.sub1 > .item > .menu.path:before {
  display: block;
}
.mobnavipart--serviceNavi div.sub1 {
  margin-top: 38px;
}
.mobnavipart--serviceNavi div.sub1 > .item {
  margin: 18px 0;
}
.mobnavipart--serviceNavi div.sub1 > .item.init {
  margin-top: 0;
}
.mobnavipart--serviceNavi div.sub1 > .item.exit {
  margin-bottom: 0;
}
.mobnavipart--serviceNavi div.sub1 > .item > .menu {
  font-size: 18px;
  line-height: 1.66666667;
  letter-spacing: 0.01em;
  color: #868686;
}
.mobnavipart--serviceNavi div.sub1 > .item > .menu.path {
  color: #000;
  font-weight: 500;
}
@media (max-width: 767px) {
  .footcontent {
    padding: 0 20px 0 80px;
    margin-bottom: 50px;
  }
  .footpart {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 50px;
  }
  .footpart:first-child {
    margin-top: 0;
  }
  .footpart--verband {
    width: 300px;
    margin-right: -20px;
    padding-bottom: 0;
  }
  .footlogo--verband {
    width: 300px;
    height: 50px;
    max-width: 120%;
    padding-bottom: 20px;
    margin-right: 0;
  }
  .footlogo--verband img {
    height: 50px;
  }
  .credits {
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.headercontent {
  padding: 12px 0;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .headercontent {
  padding: 12px 0;
}
.homelink {
  height: 72px;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .homelink {
  height: 40px;
}
.logo {
  transition: opacity 0.2s;
}
.cb-scroll-triggered--active .logo--full {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.cb-scroll-triggered--active .logo--picto {
  opacity: 1;
  transition: opacity 0.2s 0.1s;
  position: static;
}
@media (max-width: 1023px) {
  .wrapper {
    font-size: 15px;
    line-height: 1.33333333;
  }
  h1 {
    font-size: 16px;
    line-height: 2;
  }
  h3 {
    font-size: 26px;
    line-height: 1.15384615;
  }
  h4 {
    font-size: 20px;
    line-height: 1.3;
  }
  h5 {
    font-size: 18px;
    line-height: 1.22222222;
  }
  h6 {
    padding-bottom: 6px;
  }
  .loud {
    font-size: 16px;
    line-height: 1.5;
  }
  .skew {
    font-size: 16px;
    line-height: 1.375;
    padding-top: 4px;
  }
  .skew strong,
  #view .skew i {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top: -4px;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 32px;
    line-height: 1.125;
  }
}
.section--two .area .pure.wide div.line {
  margin-top: 34px;
  margin-bottom: 34px;
}
.section--two .area .fold + .fold {
  margin-top: 0px;
}
@media (max-width: 767px) {
  .section--two .area .pure.wide:not(.fold) div.pict.tall {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.wcp-widget {
  float: left;
  width: 100%;
  color: #000;
}
.wcp-widget .ns-part {
  margin-top: 30px;
}
.wcp-widget .ns-part.ns-part--title {
  font-size: 35px;
  line-height: 1.14285714;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  color: #000;
  letter-spacing: 0;
}
@media (max-width: 1023px) {
  .wcp-widget .ns-part.ns-part--title {
    font-size: 26px;
    line-height: 1.15384615;
  }
}
.wcp-widget .ns-select {
  background-color: #fff;
}
.wcp-widget .ns-select__placeholder {
  color: #909090 !important;
}
.wcp-widget .ns-form-inputitem {
  margin-top: 20px;
}
.wcp-widget .ns-input::placeholder {
  color: #909090 !important;
}
.wcp-widget .ns-choice__label {
  font-size: 16px;
  line-height: 1.625;
  color: #909090;
  padding-left: 40px;
}
.wcp-widget .ns-choice__input {
  width: 26px;
  height: 26px;
  border-radius: 0;
}
.wcp-widget .ns-choice__shape {
  border-radius: 0;
}
.wcp-widget .ns-textLink {
  font-weight: 500;
  text-decoration: underline;
  color: #000;
}
.wcp-widget .ns-upcomingDates {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .wcp-widget .ns-upcomingDates {
    display: block;
  }
}
.wcp-widget .ns-upcomingDates .ns-unit {
  width: 47.40932642%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .wcp-widget .ns-upcomingDates .ns-unit {
    width: 100%;
    margin-top: 30px;
  }
  .wcp-widget .ns-upcomingDates .ns-unit:first-child {
    margin-top: 0;
  }
}
.wcp-widget .ns-unit--chooseTour .ns-select {
  max-width: 300px;
}
.wcp-widget .ns-unit--chooseTour .ns-part--textLink {
  margin-top: auto;
  padding-top: 30px;
}
.wcp-widget .ns-calendarBoard {
  width: calc(100% + 80px);
  margin-left: -40px;
  background-color: #fff;
}
@media (max-width: 1023px) {
  .wcp-widget .ns-calendarBoard {
    width: 100%;
    margin-left: 0;
  }
}
.wcp-widget .ns-calendarFilter__month span {
  font-size: 24px;
  line-height: 1.45833333;
  font-weight: 500;
}
.wcp-widget .ns-diary__dayNameShort,
.wcp-widget .ns-diary__week,
.wcp-widget .ns-diary__dayName {
  font-size: 16px;
  line-height: 1.25;
}
@media (max-width: 1023px) {
  .wcp-widget .ns-diary__dayNameShort,
  .wcp-widget .ns-diary__week,
  .wcp-widget .ns-diary__dayName {
    font-size: 12px;
    line-height: 1.33333333;
  }
}
@media (max-width: 767px) {
  .wcp-widget .ns-diary__dayNameShort,
  .wcp-widget .ns-diary__week,
  .wcp-widget .ns-diary__dayName {
    font-size: 16px;
    line-height: 1.25;
  }
}
.wcp-widget .ns-diary__dateNumber {
  font-size: 22px;
  line-height: 1.09090909;
  padding: 5px 8px;
}
@media (max-width: 1023px) {
  .wcp-widget .ns-diary__dateNumber {
    font-size: 18px;
    line-height: 1.22222222;
    padding: 4px 8px;
  }
}
@media (max-width: 767px) {
  .wcp-widget .ns-diary__dateNumber {
    font-size: 22px;
    line-height: 1.09090909;
    padding: 0;
  }
}
.wcp-widget .ns-diary__cell--schoolFree:before,
.wcp-widget .ns-symbols__color--schoolFree,
.wcp-widget .ns-diary__cell--schoolFree .ns-diary__dateNumber {
  background-color: rgba(200, 234, 142, 0.5);
}
.wcp-widget .ns-legend {
  font-size: 16px;
  line-height: 1.375;
}
.wcp-widget .ns-symbols__item span {
  padding: 4px 0;
}
.wcp-widget .ns-subscription__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
}
.wcp-widget .ns-subscription__section:first-child {
  margin-top: 0;
}
@media (max-width: 767px) {
  .wcp-widget .ns-subscription__section {
    margin-top: 40px;
  }
}
.wcp-widget .ns-subscription .ns-unit {
  width: 29.8791019%;
  margin-top: 80px;
}
.wcp-widget .ns-subscription .ns-unit:first-child,
.wcp-widget .ns-subscription .ns-unit:nth-child(2),
.wcp-widget .ns-subscription .ns-unit:nth-child(3) {
  margin-top: 0;
}
.wcp-widget .ns-subscription .ns-unit.ns-unit--actions {
  padding-top: 22px;
}
@media (max-width: 767px) {
  .wcp-widget .ns-subscription .ns-unit.ns-unit--actions {
    padding-top: 0;
  }
}
@media (max-width: 1023px) {
  .wcp-widget .ns-subscription .ns-unit {
    width: 47.23837209%;
  }
  .wcp-widget .ns-subscription .ns-unit:nth-child(3) {
    margin-top: 80px;
  }
}
@media (max-width: 767px) {
  .wcp-widget .ns-subscription .ns-unit {
    width: 100%;
    margin-top: 40px !important;
  }
  .wcp-widget .ns-subscription .ns-unit:first-child {
    margin-top: 0 !important;
  }
}
.wcp-widget .ns-subscription .ns-label {
  border-bottom: 1px solid #868686;
  margin-bottom: 24px;
}
/*# sourceMappingURL=./screen-small.css.map */